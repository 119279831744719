:root {
  --nav-height: 70px;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(#00c4ffd9, #bbefffd9);
  color: var(--body-color);
  height: 100%;
  height: calc(100% - var(--nav-height));
  font-size: calc(10px + 2vmin);
}

.App h1 {
  font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
  color: var(--color-hot-pink);
  font-size: 12vmin;
  text-shadow: 2px 3px 2px black;
}

.App a {
  color: #dc00ff;
}

.App .navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.App .navbar .ConnectivityGauge {
  display: block;
  width: 40px;
  height: 30px;
  margin: auto;
}

.App .navbar .HandsIcon {
  display: block;
  margin: auto;
  width: 45px;
}

.App .navbar .FriendCode {
  margin: auto;
  width: 40px;
  height: 40px;
}

.App .navbar ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  height: var(--nav-height);
}

.App .navbar li {
  height: 100%;
  flex-grow: 1;
}

.App .navbar a {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: 1px solid #3f4d67;
  text-decoration: none;
  background-image: radial-gradient(circle, #9279fa, #5146a6);
  color: #ffffff;
  font-weight: bold;
  user-select: none;
}

.App .navbar ul .active {
  pointer-events: none;
  background: #5146a6;
  box-shadow: inset 0 0 20px 2px #00000054;
}
