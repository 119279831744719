:root {
  --color-hot-pink: #ff85fdd9;

  --body-color: #154a90;

  --button-bg: var(--color-hot-pink);
  --button-border-color: var(--body-color);
  --button-color: white;

  /* Should be darkened/lightened by 10% */
  --button-bg-lightened: var(--button-bg);
  --button-bg-darkened: var(--button-bg);
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Futura, Helvetica, "Trebuchet MS", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
  background-color: #ff85fdd9;
  background-color: var(--button-bg);
  background-image: -webkit-linear-gradient(#ff85fdd9, #ff85fdd9 90%);
  background-image: linear-gradient(#ff85fdd9, #ff85fdd9 90%);
  background-image: -webkit-linear-gradient(var(--button-bg-lightened), var(--button-bg) 90%);
  background-image: linear-gradient(var(--button-bg-lightened), var(--button-bg) 90%);
  color: white;
  color: var(--button-color);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid #154a90;
  border: 1px solid var(--button-border-color);
  border-radius: 0.15rem;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: bold;
  line-height: 1;
  padding: 0.7rem 0.9rem;
}

button:active {
  background-color: #ff85fdd9;
  background-color: var(--button-bg-darkened);
  background-image: none;
  border-color: #154a90;
  border-color: var(--button-border-color);
  box-shadow: inset 0 0.15rem 0.3rem #154a90;
  box-shadow: inset 0 0.15rem 0.3rem var(--button-border-color);
  outline-width: 0;
}

.MeterScreen {
  overflow: scroll;
  width: 100%;
  padding: 0.5rem 1rem;
}

.MeterScreen h1 {
  margin: 0;
}

.MeterScreen .tagline {
  margin-top: 0;
  font-style: italic;
}

.MeterScreen .ConnectivityGauge {
  max-width: 50vmin;
  margin: auto;
}

.MeterScreen .ConnectivityGauge text {
  font-weight: bold;
  text-shadow: 2px 2px 0px black;
}

.connectivity-grade-text {
  -webkit-transition: color 500ms;
  transition: color 500ms;
}

.MeterScreen .connectivity-grade-text.grade-color-red {
  color: #bb3838;
}

.MeterScreen .connectivity-grade-text.grade-color-yellow {
  color: #ff0;
}

.MeterScreen .connectivity-grade-text.grade-color-green {
  color: #2f9a2f;
}

.MeterScreen .instructions ul li {
  text-align: left;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.Viewfinder video {
  width: 100%;
  height: 100%;
}

.Viewfinder .Reticle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Viewfinder .Reticle path {
  stroke: rgb(255, 255, 255);
  stroke: rgba(255, 255, 255, 0.75);
}

.ScannerScreen {
  height: 100vh;
}

.ScannerScreen .Viewfinder {
  height: 100%;
  margin:0 -100%;
}

.ScannerScreen .text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ScannerScreen .permission-granted.text-wrapper {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0) 40%);
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0) 40%);
}

.ScannerScreen .text {
  margin: 2rem 1rem;
}

.ScannerScreen .permission-granted .text {
  color: #ffffff;
}

.FriendCode canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  width: 300px;
  max-width: 100%;
}

.FriendCodeScreen .instructions {
  padding: 1rem;
}

:root {
  --nav-height: 70px;
}

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -webkit-linear-gradient(#00c4ffd9, #bbefffd9);
  background-image: linear-gradient(#00c4ffd9, #bbefffd9);
  color: var(--body-color);
  height: 100%;
  height: calc(100% - 70px);
  height: calc(100% - var(--nav-height));
  font-size: calc(10px + 2vmin);
}

.App h1 {
  font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
  color: var(--color-hot-pink);
  font-size: 12vmin;
  text-shadow: 2px 3px 2px black;
}

.App a {
  color: #dc00ff;
}

.App .navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.App .navbar .ConnectivityGauge {
  display: block;
  width: 40px;
  height: 30px;
  margin: auto;
}

.App .navbar .HandsIcon {
  display: block;
  margin: auto;
  width: 45px;
}

.App .navbar .FriendCode {
  margin: auto;
  width: 40px;
  height: 40px;
}

.App .navbar ul {
  display: -webkit-flex;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  height: 70px;
  height: var(--nav-height);
}

.App .navbar li {
  height: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.App .navbar a {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: 1px solid #3f4d67;
  text-decoration: none;
  background-image: -webkit-radial-gradient(circle, #9279fa, #5146a6);
  background-image: radial-gradient(circle, #9279fa, #5146a6);
  color: #ffffff;
  font-weight: bold;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App .navbar ul .active {
  pointer-events: none;
  background: #5146a6;
  box-shadow: inset 0 0 20px 2px #00000054;
}

