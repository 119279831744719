.Viewfinder video {
  width: 100%;
  height: 100%;
}

.Viewfinder .Reticle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Viewfinder .Reticle path {
  stroke: rgb(255, 255, 255);
  stroke: rgba(255, 255, 255, 0.75);
}
