.MeterScreen {
  overflow: scroll;
  width: 100%;
  padding: 0.5rem 1rem;
}

.MeterScreen h1 {
  margin: 0;
}

.MeterScreen .tagline {
  margin-top: 0;
  font-style: italic;
}

.MeterScreen .ConnectivityGauge {
  max-width: 50vmin;
  margin: auto;
}

.MeterScreen .ConnectivityGauge text {
  font-weight: bold;
  text-shadow: 2px 2px 0px black;
}

.connectivity-grade-text {
  transition: color 500ms;
}

.MeterScreen .connectivity-grade-text.grade-color-red {
  color: #bb3838;
}

.MeterScreen .connectivity-grade-text.grade-color-yellow {
  color: #ff0;
}

.MeterScreen .connectivity-grade-text.grade-color-green {
  color: #2f9a2f;
}

.MeterScreen .instructions ul li {
  text-align: left;
  margin-bottom: 1rem;
  line-height: 1.3;
}
