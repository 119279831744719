.ScannerScreen {
  height: 100vh;
}

.ScannerScreen .Viewfinder {
  height: 100%;
  margin:0 -100%;
}

.ScannerScreen .text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ScannerScreen .permission-granted.text-wrapper {
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0) 40%);
}

.ScannerScreen .text {
  margin: 2rem 1rem;
}

.ScannerScreen .permission-granted .text {
  color: #ffffff;
}
