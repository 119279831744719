:root {
  --color-hot-pink: #ff85fdd9;

  --body-color: #154a90;

  --button-bg: var(--color-hot-pink);
  --button-border-color: var(--body-color);
  --button-color: white;

  /* Should be darkened/lightened by 10% */
  --button-bg-lightened: var(--button-bg);
  --button-bg-darkened: var(--button-bg);
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Futura, Helvetica, "Trebuchet MS", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
  background-color: var(--button-bg);
  background-image: linear-gradient(var(--button-bg-lightened), var(--button-bg) 90%);
  color: var(--button-color);
  user-select: none;
  border: 1px solid var(--button-border-color);
  border-radius: 0.15rem;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: bold;
  line-height: 1;
  padding: 0.7rem 0.9rem;
}

button:active {
  background-color: var(--button-bg-darkened);
  background-image: none;
  border-color: var(--button-border-color);
  box-shadow: inset 0 0.15rem 0.3rem var(--button-border-color);
  outline-width: 0;
}
